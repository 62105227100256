import { render, staticRenderFns } from "./Downloads_payhere.vue?vue&type=template&id=10b60984&scoped=true"
import script from "./Downloads_payhere.vue?vue&type=script&lang=js"
export * from "./Downloads_payhere.vue?vue&type=script&lang=js"
import style0 from "./Downloads_payhere.vue?vue&type=style&index=0&id=10b60984&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10b60984",
  null
  
)

export default component.exports