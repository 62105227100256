<template>
  <div class="hero">
    <!-- <v-parallax :src="image" class="hero"> -->
    <div class="landing">
      <div class="guide">
        <img
          :src="require(`@/assets/img/landing/ceo-landing/payhere_logo.png`)"
          style="width: 290px; margin-bottom: 32px"
        />
        <h1>
          <b>자영업 전문가들이 극찬하는</b><br />
          <b>마케팅 플랫폼 슈퍼멤버스! </b>
        </h1>
        <p style="display: flex; align-items: center">
          <b>페이히어 사장님만</b
          ><img
            :src="
              require(`@/assets/img/landing/ceo-landing/payhere_extramonth.png`)
            "
            style="width: 119px; margin-left: 8px"
          />
        </p>
        <router-link v-if="userIsAuthenticated" to="/company/modads">
          <button
            id="signupMain1"
            class="signup newPrimaryBg pl-4 py-0"
            style="line-height: 26px"
          >
            무료 상담 받아보기
            <v-icon style="color: #fff; margin: 0 0 2px 4px"
              >chevron_right</v-icon
            >
          </button>
        </router-link>
        <!-- <router-link v-else to="/signup/"> -->
        <button
          v-else
          id="signupMain2"
          class="signup newPrimaryBg pl-4 py-0"
          style="line-height: 26px"
          @click="showConsultDialog = true"
        >
          무료 상담 받아보기
          <v-icon style="color: #fff; margin: 0 0 2px 4px" icon="chevron_right"
            >chevron_right</v-icon
          >
        </button>
        <div v-if="$vuetify.breakpoint.mdAndUp"></div>
        <div style="height: 40px" v-else></div>
        <v-row justify="center" align="center" style="max-height: 0px">
          <v-dialog
            v-model="showConsultDialog"
            content-class="white-two"
            class="rounded-xl"
            max-width="400px"
            :eager="true"
          >
            <v-card class="pa-8">
              <v-card-text>
                <div style="min-height: 640px">
                  <div
                    class="pipedriveWebForms"
                    data-pd-webforms="https://webforms.pipedrive.com/f/72oibMyDnObCLRYR3MM7wmyITXsXhZrD5xTPexpxP3Qp0JlvbWxya3YUyVIZLPY8Uj"
                  ></div>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <img
        v-if="$vuetify.breakpoint.mdAndUp"
        :src="require(`@/assets/img/landing/ceo-landing/payhere_banner.png`)"
        style="width: 596px; margin-left: 60px"
      />
      <img
        v-else
        :src="
          require(`@/assets/img/landing/ceo-landing/payhere_banner_mobile.png`)
        "
      />
    </div>
    <div class="landing">
      <div class="guide">
        <h1><b>네이버 블로그 상위노출로</b><br />새로운 고객을 만나보세요.</h1>
        <p>
          <b>상위노출 가능성</b>이 높은 블로거의 참여를 통해<br />
          <b>원하는 키워드로 상위노출</b>을 시킬 수 있습니다.
        </p>
      </div>
      <img
        v-if="$vuetify.breakpoint.mdAndUp"
        :src="require(`@/assets/img/landing/ceo-landing/level.png`)"
        style="width: 596px; margin-left: 60px"
      />
      <img
        v-else
        :src="require(`@/assets/img/landing/ceo-landing/level-mobile2.png`)"
      />
    </div>
  </div>
</template>

<script>
import userMixin from '@/components/shared/userMixin';

export default {
  name: 'Landing',
  mixins: [userMixin],
  components: {},
  mounted() {
    window.setInterval(() => {
      this.updatePrimary();
    }, 8600);

    let pipedriveScript = document.createElement('script');
    pipedriveScript.setAttribute(
      'src',
      'https://webforms.pipedrive.com/f/loader'
    );
    document.head.appendChild(pipedriveScript);
  },
  methods: {
    updatePrimary: function () {
      this.$nextTick(function () {
        this.primary = (this.primary + 1) % 4;
      });
    },
    moveBlogSite() {
      location.href = 'https://rank.supermembers.co.kr/';
    },
  },
  data() {
    return {
      showConsultDialog: false,
      // primary: 0,
      //   BlogData1: {
      //     title: "가로수길 카페",
      //     contents: [
      //       {
      //         imageName: 'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Fsteak1.jpg?alt=media&token=f862de29-eab1-4281-b1c1-c60eaeaa749d',
      //         title: "가로수길 카페 더닐크팩토리 건강한케이크 맛도좋아",
      //         body: "비건밀크, 닐크만 제공되고 있다고해요 ! 아이에게 먹이고 몸 생각한다면 건강한 케이크 먹여야겠죠?!"
      //       },
      //       {
      //         imageName: 'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Fsteak2.jpg?alt=media&token=afc7badb-751a-418a-9547-c5971e68a30d',
      //         title: "가로수길 디저트 카페 더닐크팩토리 케이크 먹었어요",
      //         body: "신사동 가로수길 디저트 더닐크팩토리 케이크 먹고왔어요. 신사동 더닐크팩토리, 주변 분들이 여기 당근 케이크가 맛있다고 추천해서 알게되었어요."
      //       },
      //       null,
      //       {
      //         imageName: 'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Fsteak3.jpg?alt=media&token=d8e6f678-77d9-4a3d-b8ac-874b605b370c',
      //         title: "가로수길카페 글루텐프리 더닐크팩토리",
      //         body: "친구와 함께 가로수길에서 밥을 먹고 찾아간 더닐크팩토리 예쁘고 아담하다… 어머니가 당근 케이크 좋아하시는데 닐크팩토리꺼 사다드리면 진짜 좋아하실듯!"
      //       },
      //       null
      //     ]
      //   },
      //   BlogData2: {
      //     title: "홍대입구 네일샵",
      //     contents: [
      //       {
      //         imageName: 'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Fnail1.jpg?alt=media&token=2f86e9b5-973d-4afb-9cd0-353c4e59c696',
      //         title: "홍대입구 네일샵 라끄블뢰네일에서 네일아트~!",
      //         body: "예쁜 디자인들이 너무 많았던 라끄블뢰네일, 네일샵 가기 전부터 디자인은 정해뒀었지만.. 예쁜 디자인들이 너무 많아 케어받으면서 결정장애 발동!"
      //       },
      //       {
      //         imageName: 'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Fnail2.jpg?alt=media&token=307cb1c8-3851-4fdf-92ef-e78e7eadfd9d',
      //         title: "여름 젤네일아트 네일샵 홍대입구 라끄블뢰 너무 예뻐요!",
      //         body: "날씨 덥고 기분은 가라앉을때는 시원한 여름 젤네일이 최고! 바디 한가득 올린 자개와 글리터로 화려함의 정점을 향해 달려가고 있답니다..^^"
      //       },
      //       null,
      //       {
      //         imageName: 'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Fnail3.jpg?alt=media&token=26a2107a-45d8-4e6c-9039-3cfc01d7e7da',
      //         title: "홍대입구 네일샵 금손원장님 라끄블뢰네일 추천!!",
      //         body: "친구 추천으로 찾아간 라끄블뢰네일 후기를..:) 제가 찾아간 디자인이 있었는데, 원장님께서 라끄블뢰만의 스타일로 더 이쁘게 만들어주셨답니다! 반짝임이 정말이쁜 글리터네일 :D"
      //       },
      //       null
      //     ]
      //   },
      //   Insta1: {
      //     profile: '',
      //     image: [
      //       'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Fcosmetics3.jpg?alt=media&token=ffb5251d-a3d3-4fa6-875a-e1e9fa38bacd',
      //       'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Fcosmetics2.jpg?alt=media&token=d1b22e0c-abaf-4cab-9c00-cadfdc671442',
      //       'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Fcosmetics1.jpg?alt=media&token=70b1c454-6bea-448b-8960-0f24def21abc',
      //     ],
      //     name: 'IamInstagramer',
      //     tags: '#화장품선물 #기초화장품 #화이트닝 #브라이트닝',
      //     text: '단순한 라인별이 아닌 피부타입별 상태별로 세분화된 제품 시스템이라 2차 클린징 종류만도 6개가 있어요'
      //   },
      //   Insta2: {
      //     profile: '',
      //     image: [
      //       'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Fdia3.jpg?alt=media&token=ef87b247-d9c4-4785-b5df-d6c1c8998ca2',
      //       'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Fdia2.jpg?alt=media&token=9f2a5756-2f03-492c-8fd5-727342430d10',
      //       'https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Fdia1.jpg?alt=media&token=690e6ac7-a7b5-4b33-88b0-29107226755b',
      //     ],
      //     name: 'supermems',
      //     tags: '#다이아몬드베이 #광안대교 #요트파티 #부산여행 #힐링타임',
      //     text: '광안리-해운대코스 요트투어 :D 바다위를 둥둥 해외여행 온것같아 '
      //   }
    };
  },
};
</script>

<style scoped lang="scss">
.hero {
  // background-image: url('../../assets/img/landing/hero_tinyfied.jpg');
  // background-size: cover;
  // background-position: center;
  // height: 720px;

  background-color: #fff;
}

.landing {
  // height: 592px;
  display: flex;
  overflow: hidden;
  margin: 0 auto;
  padding-top: 128px;
  // justify-content: space-between;
  justify-content: center;
  padding-bottom: 128px;
  * {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .guide {
    // margin: 0 auto;
    text-align: left;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    // margin-right: 55px;
    h1 {
      line-height: 1.4;
      margin-bottom: 32px;
      font-weight: 300 !important;
      color: #0f1633;
      b {
        font-weight: 700;
      }
    }
    p {
      color: #1b2b43;
      margin-bottom: 56px;
      font-size: 18px;
      letter-spacing: 0.01em;
    }
  }
}

@media (max-width: 724px) {
  .hero {
    // background-image: url('../../assets/img/landing/hero_tinyfied.jpg');
  }
  .landing {
    // height: calc(100vh - 120px);
    padding: 50px 16px 0;
    flex-direction: column;
    text-align: center;
    .guide {
      align-items: center;
      text-align: center;

      h1 {
        font-size: 29px;
        line-height: 1.44;
        margin-bottom: 30px;
        letter-spacing: 0.02em !important;
      }
      p {
        margin-bottom: 50px;
        font-size: 14px;
        line-height: 1.79;
        // display: none;
      }
      #blogButton {
        margin-bottom: 40px;
      }
    }
  }
}

.signup {
  width: 220px;
  height: 48px;
  border-radius: 24px;
  box-shadow: 0 12px 24px 0 rgba(16, 16, 16, 0.32);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.blackBg {
  background-color: #212121;
}

.primaryBg {
  background-color: #bd1328;
}

.newPrimaryBg {
  background-color: #ea2a3a;
}
</style>
